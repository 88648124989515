import { DateTime } from 'luxon';

export const formatArticlesUrl = (origin?: string | null) => {
  return formatUrl(`/`, origin);
};

export const formatArticleUrl = (slug: string, origin?: string | null) => {
  return formatUrl(`/article/${slug}`, origin);
};

export const formatArticlePreviewUrl = (
  slug: string,
  origin?: string | null,
) => {
  return formatUrl(`/preview/${slug}`, origin);
};

export const formatTagsUrl = (origin?: string | null) => {
  return formatUrl(`/discover`, origin);
};

export const formatTagUrl = (slug: string, origin?: string | null) => {
  return formatUrl(`/discover/${slug}`, origin);
};

export const formatUrl = (url: string, origin?: string | null) => {
  return `${origin ?? ''}${url}`;
};

export const setCookie = (name: string, value: string, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export const formatHeadingDate = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  const today = DateTime.now();
  const yesterday = today.minus({ days: 1 });

  if (date.hasSame(today, 'day')) {
    return `Today - ${date.toFormat('yyyy-MM-dd')}`;
  } else if (date.hasSame(yesterday, 'day')) {
    return `Yesterday - ${date.toFormat('yyyy-MM-dd')}`;
  } else {
    return date.toFormat('cccc, yyyy-MM-dd');
  }
};

export function getPageTypeAndSlug(pathname: string) {
  const pathSegments = pathname.split('/').filter(Boolean);

  let type = 'main';
  let slug = '';

  if (pathSegments.length > 0) {
    if (pathSegments[0] === 'article') {
      type = 'article';
      slug = pathSegments[1];
    } else if (pathSegments[0] === 'discover' && pathSegments[1]) {
      type = 'discover';
      slug = pathSegments[1];
    }
  }
  return { type, slug };
}

export const capitalizeText = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

export const formatAmount = (count: number) => {
  if (Math.floor(count / 1000000) > 0) {
    return `+${Math.floor(count / 1000000)}M`;
  }

  if (Math.floor(count / 1000) > 100) {
    return `+${Math.floor(count / 1000)}K`;
  }

  if (Math.floor(count / 1000) > 10) {
    return `+${Math.floor(count / 1000)}K`;
  }

  if (Math.floor(count / 1000) > 0) {
    return `+${Math.floor(count / 1000)}K`;
  }

  if (count <= 1000) {
    return count;
  }

  return count;
};
